<template>
  <div class="page-container">
    <page-header></page-header>
    <div class="dashboard-container">
      <div class="page-card">
        <div class="page-card-header">
          <span>{{ $route.meta.title }}</span>
        </div>
        <div class="page-card-body">
          <el-form :model="editPageForm" :rules="editRules" label-width="100px" ref="editPageForm"
                   label-position="left" v-loading="edit_form_loading">
            <el-row>
              <el-col :span="8">
                <el-form-item label="名称" prop="name" >
                  <el-input style="width: 300px;" placeholder="请输入角色名称,20字符内" v-model="editPageForm.name" maxlength="20"></el-input>
                </el-form-item>
                <el-form-item label="是否管理员" prop="isAdmin"  style="width: 60%;">
                  <el-radio-group v-model="editPageForm.isAdmin">
                    <el-radio :label="true">是</el-radio>
                    <el-radio :label="false">否</el-radio>
                  </el-radio-group>
                </el-form-item>
                <el-form-item label="描述" prop="describe" >
                  <el-input placeholder="请输入角色描述，100字符内" style="width: 300px;" v-model="editPageForm.describe" maxlength="100"
                            rows="5" type="textarea"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="16" class="assign-permissions-container">
                <div class="label-title">
                  分配权限 / 小程序
                </div>
                <el-row class="menu-row" v-for="(iter, index) in menusAllWx" :key="iter.id"
                        :style="{borderTopColor: index === 0 ? '#DCDFE6' : ''}">
                  <el-col :span="4" >
                    <el-checkbox-group v-model="iter.checkedCities"
                                       @change="handleCheckedCitiesChange($event, iter)">
                      <el-checkbox :label="iter.id">{{ iter.name }}</el-checkbox>
                      <el-checkbox v-for="item in iter.child"
                                   :key="item.id"
                                   :label="item.id">{{ item.name }}</el-checkbox>
                    </el-checkbox-group>
                  </el-col>
                  <el-col :span="17">
                    <el-checkbox-group v-model="iter.checkedCitiesAuth"
                                       @change="handleAuthCitiesChange($event, iter)">
                      <el-checkbox v-for="item in iter.menuAuths"
                                   :key="item.id"
                                   :label="item.id">{{ item.name }}</el-checkbox>
                    </el-checkbox-group>
                  </el-col>
                </el-row>

                <div class="label-title">
                  分配权限 / PC
                </div>
                <el-row v-for="(iter, index) in menusAllPc" :key="iter.id"
                        :style="{borderTopColor: index === 0 ? '#DCDFE6' : ''}">
                  <el-col :span="24">
                    <el-row>
                      <el-col :span="4" style="background-color: #4286de00;">
                        <el-checkbox-group v-model="iter.checkedCities"
                                           @change="handleCheckedCitiesChange($event, iter)">
                          <div class="menu-row" :style="{borderTopColor: index === 0 ? '#DCDFE6' : ''}">
                            <el-checkbox :label="iter.id">{{ iter.name }}</el-checkbox>
                          </div>
                          <div class="menu-row" v-for="item in iter.child"
                               :key="item.id">
                            <el-checkbox
                                :label="item.id">{{ item.name }}</el-checkbox>
                          </div>
                        </el-checkbox-group>
                      </el-col>
                      <el-col :span="20" style="background-color: #9ce18a00;">
                        <div class="menu-row permission-button"><span style="opacity: 0;">*</span></div>
                        <div class="menu-row permission-button" v-for="item in iter.child" :key="item.id">
                          <el-checkbox-group v-model="item.checkedCitiesAuth"
                                             style="width: 100%;height: 100%;"
                                             @change="handleAuthCitiesChange($event, item)">
                            <div style="width: 100%;" v-if="10">
                              <el-checkbox v-for="k in item.menuAuths"
                                           :key="k.id"
                                           :label="k.id">{{ k.name }}</el-checkbox>
                              <div v-if="item.menuAuths.length === 0" style="height: 20px;"><span style="opacity: 0;">*</span></div>
                            </div>
                          </el-checkbox-group>
                        </div>
                      </el-col>
                    </el-row>
                  </el-col>
                </el-row>
              </el-col>
            </el-row>
            <div class="dialog-footer">
              <el-button @click="goBack" type="info">取 消</el-button>
              <el-button @click="editSubmit('editPageForm')" type="primary">确 定</el-button>
            </div>
          </el-form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PageHeader from "@/components/PageHeader"
import {roleCreate, menuAll, roleInfo, roleMenus, roleUpdate} from "@/api/common"

export default {
  name: "RoleInfo",
  data() {
    return {
      // checkAll: false,
      // checkedCities: [],
      // cities: [],
      // isIndeterminate: true,

      // 菜单 权限
      menusAllWx: [],
      menusAllPc: [],
      //
      edit_form_loading: false,
      editPageForm: {
        name: '',
        describe: '',
        menuIds: '',
        menuIdsArr: [],
        isAdmin: true,
      },
      editRules: {
        name: [
          {required: true, message: '请输入角色名称', trigger: 'blur'},
          {max: 20, message: '角色名称20字符以内', trigger: 'blur'},
        ],
        isAdmin:[
          {required: true, message: '请选择是否为管理员', trigger: 'blur'},
        ],
        describe: [
          {required: true, message: '请输入角色描述', trigger: 'blur'},
          {max: 100, message: '角色描述20字符以内', trigger: 'blur'},
        ],
      },
      menus: [],
      // 按钮
      menuAuthIds: [],
      // 菜单
      menuIds: [],
    }
  },
  components: {
    PageHeader,
  },
  created() {
    this.id = this.$route.params.id
  },
  mounted() {
    this.getMenuAll()
  },
  methods: {
    getRoleMenus() {
      let that = this
      roleMenus(that.id).then(res => {
        let role_menus = []
        for (let i in res) {
          role_menus.push(res[i].name)
        }
        // this.role_menus = role_menus
      })
    },
    /**
     * 菜单
     * @param val
     * @param iter
     */
    handleCheckAllChange(val, iter) {
      const selectArrAll = []
      if (iter) {
        selectArrAll.push(iter.id)
        if (iter.child) {
          iter.child.forEach(item => selectArrAll.push(item.id))
        }
      }
      // 当前行选中
      iter.checkedCities = val ? selectArrAll : []
      // 样式
      iter.isIndeterminate = false;
    },
    /**
     * 权限
     * @param val
     * @param item
     */
    handleAuthAllChange(val, item) {
      const selectArrAll = []
      if (item) {
        if (item.menuAuths) {
          item.menuAuths.forEach(item => selectArrAll.push(item.id))
        }
      }
      // 当前行选中
      item.checkedCitiesAuth = val ? selectArrAll : []
      // 样式
      item.isIndeterminateAuth = false;
    },
    /**
     * 菜单
     * @param value
     * @param iter
     */
    handleCheckedCitiesChange(value, iter) {
      const checkedCount = value.length;
      iter.checkAll = checkedCount === (iter.child.length + 1);
      iter.isIndeterminate = checkedCount > 0 && checkedCount < (iter.child.length + 1);
    },
    /**
     * 权限
     */
    handleAuthCitiesChange(value, iter){
      const checkedCount = value.length;
      iter.checkAllAuth = checkedCount === iter.menuAuths.length;
      iter.isIndeterminateAuth = checkedCount > 0 && checkedCount < iter.menuAuths.length;
    },

    getMenuAll() {
      menuAll().then(res => {
        // 菜单
        const menusAllWx = res.wx
        const menusAllPc = res.pc

        menusAllWx.map(iter => {
          // 菜单
          iter.checkedCities = []
          iter.isIndeterminate = false
          iter.checkAll = false

          // 权限
          iter.checkedCitiesAuth = []
          iter.isIndeterminateAuth = false
          iter.checkAllAuth = false
        })
        menusAllPc.map(iter => {
          iter.checkedCities = []
          iter.isIndeterminate = false
          iter.checkAll = false

          iter.child.map(item => {
            item.checkedCitiesAuth = []
            item.isIndeterminateAuth = false
            item.checkAllAuth = false
          })
        })

        this.menusAllWx = menusAllWx
        this.menusAllPc = menusAllPc
        // --
        this.getRoleInfo()

        // -
        this.$nextTick(() => {
          const doms = document.querySelectorAll('.permission-button')
          if (doms.length > 0) {
            const firstElement = doms[0]
            if (firstElement !== null) {
              firstElement.style.borderTopColor = '#DCDFE6'
            }
          }
        })
      })
    },
    /**
     * 获取用户权限
     */
    getRoleInfo() {
      if (this.id) {
        roleInfo(this.id).then(res => {
          // 编辑
          this.editPageForm.id = res.id
          this.editPageForm.name = res.name
          this.editPageForm.describe = res.describe
          this.editPageForm.version = res.version
          this.editPageForm.isAdmin = res.isAdmin || false

          // 菜单
          const menuIds = (res.menuIds || '').split(',')
          // 小程序
          this.menusAllWx.map(iter => {
            menuIds.forEach(item => {
              if (item === iter.id) {
                iter.checkedCities.push(item)
              }
            })
          })
          // pc
          menuIds.forEach(item => {
            this.menusAllPc.map(iter => {
              iter.child.forEach(k => {
                if (k.id === item) {
                  iter.checkedCities.push(item)
                }
              })
              if (item === iter.id) {
                iter.checkedCities.push(item)
              }
            })
          })

          // 功能
          const menuAuthIds = (res.menuAuthIds || '').split(',')
          // 小程序
          this.menusAllWx.map(iter => {
            const childArr = []
            iter.menuAuths.forEach(item => childArr.push(item.id))
            childArr.forEach(item => {
              if (menuAuthIds.includes(item)) {
                iter.checkedCitiesAuth.push(item)
              }
            })
          })
          // pc
          menuAuthIds.forEach(item => {
            this.menusAllPc.map((iter) => {
              iter.child.map(k => {
                k.menuAuths.forEach(l => {
                  if (l.id === item) {
                    k.checkedCitiesAuth.push(item)
                  }
                })
              })
            })
          })
        })
      }
    },
    editSubmit(formName) {
      // console.log(this.menusAllWx, '---------menusAllWx--');
      // console.log(this.menusAllPc, '---------menusAllPc--');
      const checkedCities = []
      const checkedCitiesAuth = []

      // 获取小程序权限
      this.menusAllWx.forEach(iter => {
        // 菜单
        iter.checkedCities.forEach(item => checkedCities.push(item))
        // 权限
        iter.checkedCitiesAuth.forEach(item => checkedCitiesAuth.push(item))
      })
      // 获取PC权限
      this.menusAllPc.forEach(iter => {
        // 菜单
        iter.checkedCities.forEach(item => checkedCities.push(item))
        // 权限
        iter.child.forEach(item => {
          item.checkedCitiesAuth.forEach(k => {
            if (!checkedCitiesAuth.includes(k)) {
              checkedCitiesAuth.push(k)
            }
          })
        })
      })
      // console.log(checkedCities, '-------checkedCities-');
      // console.log(checkedCitiesAuth, '-------checkedCitiesAuth-');

      // ------
      let that = this
      // 菜单权限
      that.editPageForm.menuIds = checkedCities.join(",");
      // 按钮权限
      that.editPageForm.menuAuthIds = checkedCitiesAuth.join(',')

      // 编辑 | 创建
      const handler = this.$route.params.id ? roleUpdate : roleCreate

      that.$refs[formName].validate((valid) => {
        if (!valid) {
          return false
        }
        that.eidt_form_loading = true
        handler(that.editPageForm).then(() => {
          that.eidt_form_loading = false
          that.$message.success('提交成功')
          that.$router.push({path: '/admin/role'})
        }).catch(() => {
          that.eidt_form_loading = false
        })
      })
    },
    goBack() {
      this.$router.push({path: '/admin/role'})
    },
  },
}
</script>

<style lang="scss" scoped>
.page-container{
  .dashboard-container{
    .page-card{
      .page-card-header{
        padding-bottom: 0;
        flex-direction: column;
        align-items: flex-start;
        border-bottom: none;
        font-weight: bold;
        &::after{
          padding-top: 20px;
          content: ' ';
          width: 100%;
          height: 1px;
          border-bottom: 1px solid #ebeef5;
        }
      }
    }
  }
}
.page-card-body {
  padding: 20px;
  .table-action-item {
    display: inline-block;
    cursor: pointer;
    color: $--color-primary;

    & + .table-action-item {
      margin-left: 10px;
    }
  }
}
.assign-permissions-container{
  height: 620px;
  overflow-y: auto;
  //scrollbar-width: none;
  //&::-webkit-scrollbar {
  //  display: none;
  //}

  .label-title{
    display: flex;
    align-items: center;
    margin-bottom: 22px;
    height: 40px;
    color: #606266;
    font-size: 14px;
  }

  .menu-row{
    padding: 16px 0;
    border-top: 1px solid #EEF1F5;
    width: 100%;
    color: #606266;
    font-size: 14px;
  }
}
</style>